import React from 'react'

export default function Card() {
  return (
    <section id='card' className='card'>
        <h2 className='cardh2'>КАРТОЧКА УЧЕТА ОСНОВНЫХ СВЕДЕНИЙ <br/> ООО «ТД МТК»</h2>
        <table className='table'>
            <tr>
                <td>Полное название фирмы в соответствии с учредительными документами</td>
                <td>Общество с ограниченной ответственностью «Торговый Дом МТК»</td>
            </tr>
            <tr>
                <td>Сокращенное наименование</td>
                <td>ООО «ТД МТК»</td>
            </tr>
            <tr>
                <td>Юридический адрес в соответствии с учредительными документами</td>
                <td>105203, г. Москва, ул. 14-я Парковая, д.8 помещение 8/5</td>
            </tr>
            <tr>
                <td>Почтовый адрес:</td>
                <td></td>
            </tr>
            <tr>
                <td>Контактный тел:</td>
                <td>+7 (901) 349-49-49</td>
            </tr>
            <tr>
                <td>E-mail:</td>
                <td>td.mtk@mail.ru</td>
            </tr>
            <tr>
                <td>Генеральный директор</td>
                <td>Катаев Владислав Витальевич</td>
            </tr>
            <tr>
                <td>ИНН</td>
                <td>9719063493</td>
            </tr>
            <tr>
                <td>КПП</td>
                <td>771901001</td>
            </tr>
            <tr>
                <td>ОГРН</td>
                <td>1247700132591</td>
            </tr>
            <tr>
                <td>Расчетный счет </td>
                <td>40702810310001562220</td>
            </tr>
            <tr>
                <td>Корреспондентский счет банка</td>
                <td>30101810145250000974</td>
            </tr>
            <tr>
                <td>БИК</td>
                <td>044525974</td>
            </tr>
            <tr>
                <td>Полное наименование банка</td>
                <td>АО «ТИНЬКОФФ БАНК»</td>
            </tr>
        </table>




    </section>
  )
}
