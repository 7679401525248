import React from 'react'
import Swiper from './Swiper'


export default function Info() {
  return (
    <section id='info' className='info'>
      <div className='swip'>
        <Swiper />
      </div>
        
        

    </section>
  )
}
